*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.gridcontainer
{
    /* background-color: aqua; */
    display: grid;
    width: 100%;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    grid-gap: 10px;
    row-gap: 20px;
    justify-items: center;
    margin-top: 80px;

}

.watchlistcardcontainer{
    height: 270px;
}

.poster
{
    border-radius: 5px;
    transition: transform .2s;
}

.poster:hover{
    transform: scale(1.05);
}


.buttoncontrols{
    position: relative;
    bottom: 50px;
    left: 13px;
    display: none;
}

.poster:hover + .buttoncontrols{
    display: block;
}

.buttoncontrols:hover{
    display: block;
}

.buttoncontrols button{
    cursor: pointer;
    background-color: black;
    opacity: 0.9;
    outline: none;
    border: none;
    border-radius: 5px;
    color: white;
    margin: 3px;
    padding: 5px;
}

.removebtn:hover
{
    color: red;
}

.watchedbtn:hover{
    color: forestgreen;
}

.watchlistconatiner
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
}

.heading{
    margin-top: 40px;
    color: #FFD369;
    border-color: black;
    border-width: 10px;

}

.nothin{
    color: rgb(224, 211, 211);
    position: relative;
    margin-top: 300px;
    text-align: center;
}

@media only screen and (max-width:600px) {
    .gridcontainer
    {
        grid-template-columns: repeat( auto-fit, minmax(170px, 1fr) );
    }
}

