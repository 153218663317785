@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
}


.headcontainer
{
    height: 70px;
    background-color: #222831;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.logotxt
{
    color: #FFD369;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.links
{
    display: flex;
    width: 65%;
    justify-content: space-around;
}

.linkbtns{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 300;
    margin-right: 5px;
    margin-left: 5px;
}

.addbutton
{
    background-color: #FFD369;
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
    color: black;

}

@media only screen and (max-width:600px) {
    .logotxt
    {
        font-size: 17px;
    }

    .linkbtns
    {
        font-size: 14px;
    }

    .addbutton
    {
        font-size: 14px;
    }
}
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.gridcontainer
{
    /* background-color: aqua; */
    display: grid;
    width: 100%;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    grid-gap: 10px;
    grid-row-gap: 20px;
    row-gap: 20px;
    justify-items: center;
    margin-top: 80px;

}

.watchlistcardcontainer{
    height: 270px;
}

.poster
{
    border-radius: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}

.poster:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}


.buttoncontrols{
    position: relative;
    bottom: 50px;
    left: 13px;
    display: none;
}

.poster:hover + .buttoncontrols{
    display: block;
}

.buttoncontrols:hover{
    display: block;
}

.buttoncontrols button{
    cursor: pointer;
    background-color: black;
    opacity: 0.9;
    outline: none;
    border: none;
    border-radius: 5px;
    color: white;
    margin: 3px;
    padding: 5px;
}

.removebtn:hover
{
    color: red;
}

.watchedbtn:hover{
    color: forestgreen;
}

.watchlistconatiner
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
}

.heading{
    margin-top: 40px;
    color: #FFD369;
    border-color: black;
    border-width: 10px;

}

.nothin{
    color: rgb(224, 211, 211);
    position: relative;
    margin-top: 300px;
    text-align: center;
}

@media only screen and (max-width:600px) {
    .gridcontainer
    {
        grid-template-columns: repeat( auto-fit, minmax(170px, 1fr) );
    }
}


*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.container
{
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    justify-content: center;
}

.poster
{
    height: 250px;

}

.cardinfo
{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 30px;
}

.textinfo
{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 15px;
}

.button
{
    background-color: #FFD369;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-weight: bold;
}

.discription{
    font-size: 12px;
    margin-top: 10px;
}
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.addpagecontainer
{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-bottom: 70px;

}

.inputcontainer
{
    width: 50%;
    margin-top: 70px;
    margin-bottom: 30px;
}

.inputcontainer input
{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    outline: none;
    border:oldlace;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    background-color: #222831;
    color: white;
    padding-left: 30px;
}

.inputcontainer input::-webkit-input-placeholder
{
    color: white;
    padding-left: -30px;
}

.inputcontainer input:-ms-input-placeholder
{
    color: white;
    padding-left: -30px;
}

.inputcontainer input::placeholder
{
    color: white;
    padding-left: -30px;
}

.movielists
{
    width: 70%;
    margin-top: 30px;
}

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.footercontainer
{   
    position: fixed;
    width: 100%;
    height: 50px;
    background-color: #222831;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    bottom: 0;
}

.footertitle
{
    color: #FFD369;
}

@media only screen and (max-width:600px) {
    .footertitle
    {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
    }
}
