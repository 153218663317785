
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap');

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
}


.headcontainer
{
    height: 70px;
    background-color: #222831;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.logotxt
{
    color: #FFD369;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.links
{
    display: flex;
    width: 65%;
    justify-content: space-around;
}

.linkbtns{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 300;
    margin-right: 5px;
    margin-left: 5px;
}

.addbutton
{
    background-color: #FFD369;
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
    color: black;

}

@media only screen and (max-width:600px) {
    .logotxt
    {
        font-size: 17px;
    }

    .linkbtns
    {
        font-size: 14px;
    }

    .addbutton
    {
        font-size: 14px;
    }
}